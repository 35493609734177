@font-face {
  font-family: 'Avenir';
  src: url('/fonts/Avenir-Medium.otf');
  font-weight: 500;
}
@font-face {
  font-family: 'Avenir';
  src: url('/fonts/Avenir-Medium.otf');
  font-weight: 600;
}
@font-face {
  font-family: 'Avenir';
  src: url('/fonts/Avenir-Heavy.otf');
  font-weight: 800;
}
@font-face {
  font-family: 'Avenir';
  src: url('/fonts/Avenir.otf');
  font-weight: normal;
}

/* PP Neue Montreal font-face definitions */
@font-face {
  font-family: 'PP Neue Montreal';
  src: url('/fonts/ppneuemontreal-bold.otf');
  font-weight: bold;
}
@font-face {
  font-family: 'PP Neue Montreal';
  src: url('/fonts/ppneuemontreal-book.otf');
  font-weight: normal;
}
@font-face {
  font-family: 'PP Neue Montreal';
  src: url('/fonts/ppneuemontreal-italic.otf');
  font-style: italic;
  font-weight: normal;
}
@font-face {
  font-family: 'PP Neue Montreal';
  src: url('/fonts/ppneuemontreal-medium.otf');
  font-weight: 500;
}
@font-face {
  font-family: 'PP Neue Montreal';
  src: url('/fonts/ppneuemontreal-semibolditalic.otf');
  font-style: italic;
  font-weight: 600;
}
@font-face {
  font-family: 'PP Neue Montreal';
  src: url('/fonts/ppneuemontreal-thin.otf');
  font-weight: 100;
}
// @font-face {
//   font-family: 'Butler';
//   src: url('/fonts/Butler_Regular.otf');
// }

@font-face {
  font-family: 'ppneuemontreal';
  src: url('/fonts/ppneuemontreal-thin.otf');
}

@import '~bootstrap/scss/bootstrap';
@import 'styles/Theme.scss';

* {
  box-sizing: content-box;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style: none;
  margin: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  font-family: $REGULARFONT;
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
  &:focus {
    outline: none;
  }
}

h1 {
  @include h1();
}

h2 {
  @include h2();
}

h3 {
  @include h3();
}
h4 {
  @include h4();
}
strong {
  font-weight: bold;
}
p,
li,
label,
input {
  white-space: pre-wrap;
  font-family: $REGULARFONT;
  margin: 0;
  @include normal();
  &.strong {
    @include strong();
  }
  &.small {
    @include small();
  }
  &.bold {
    @include normal();
    font-weight: 800;
  }
  &.caption {
    @include caption();
  }
}
span {
  font-family: $REGULARFONT;
}

small {
  @include small();
}

caption {
  @include caption();
}
input,
textarea {
  border-radius: 2px;
  background-clip: padding-box;
  ::-webkit-input-placeholder,
  ::-moz-placeholder,
  ::-ms-input-placeholder,
  ::-moz-placeholder {
    font-family: $REGULARFONT;
  }
}
